import { ThemeInteractive } from '../../theme'
import { BAUPAL_COLORS } from './palette'

export default {
    primary: {
        default: {
            background: BAUPAL_COLORS.generic.neutral[100],
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.neutral[100],
        },
        focus: {
            background: BAUPAL_COLORS.generic.neutral.light,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.neutral.standard,
        },
        hover: {
            background: BAUPAL_COLORS.generic.neutral.light,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.neutral.standard,
        },
        disabled: {
            background: BAUPAL_COLORS.generic.neutral[10],
            color: BAUPAL_COLORS.generic.neutral[40],
            border: BAUPAL_COLORS.generic.neutral[10],
        },
        active: {
            background: BAUPAL_COLORS.generic.neutral.light,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.neutral.standard,
        },
    },
    secondary: {
        default: {
            background: BAUPAL_COLORS.generic.green.standard,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.green.standard,
        },
        focus: {
            background: BAUPAL_COLORS.generic.green.light,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.green.standard,
        },
        hover: {
            background: BAUPAL_COLORS.generic.green.light,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.green.light,
        },
        disabled: {
            background: BAUPAL_COLORS.generic.neutral[10],
            color: BAUPAL_COLORS.generic.neutral[40],
            border: BAUPAL_COLORS.generic.neutral[10],
        },
        active: {
            background: BAUPAL_COLORS.generic.green.light,
            color: BAUPAL_COLORS.generic.neutral.white,
            border: BAUPAL_COLORS.generic.green.standard,
        },
    },
} as ThemeInteractive
