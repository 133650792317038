import { dataDog } from 'lib/utils/dataDog'
dataDog('user-portal')

import 'react-toastify/dist/ReactToastify.css'

import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query'
import { createEnterApi } from '@api/createApi'
import { createQueryClient } from 'lib/api/apiFactory'

import { AppProps } from 'next/app'
import { Global } from '@emotion/react'
import { GoogleTagManagerScript } from 'lib/utils/googleTagManager'
import Head from 'next/head'
import { FEATURES, SONIC_AUTH_CONFIG } from '@utils/authentication'
import { ToastClient } from 'lib/components/toast'
import { globalStyles } from '@styles/global'
import { useState } from 'react'
import { EnterApiProvider } from 'lib/api/apiContext'
import {
    AUTH_USER_UUID,
    EnterAuthConfigProvider,
    IMPERSONATION_ADMIN_TOKEN,
    USER_CUSTOM_TOKEN
} from 'lib/utils/authentication'
import { ModalRoot } from 'lib/components/modal'
import { SelectRoot } from 'lib/components/forms/select'
import { EnterPageProps } from 'lib/utils/next'
import { featureContext } from 'lib/utils/authentication'
import { ScreenSizeProvider } from 'lib/context/screenSizeContext'
import * as process from 'process'
import { NextPageContext } from 'next'
import { UserStatusContextProvider } from '@common/context/userStatusContext'
import { FirebaseAuthenticationProvider } from 'lib/components/authentication/authenticationProvider'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FirebaseCustomAuthentication } from "@modules/FirebaseCustomAuthentication";

App.getInitialProps = async (ctx: { router: { query: {
    [IMPERSONATION_ADMIN_TOKEN]: string,
    [USER_CUSTOM_TOKEN]: string,
    [AUTH_USER_UUID]: string,
} } } & NextPageContext) => {
    const adminToken = ctx.router?.query?.[IMPERSONATION_ADMIN_TOKEN]
    const userCustomToken = ctx.router?.query?.[USER_CUSTOM_TOKEN] || ''
    const authUserUUID = ctx.router?.query?.[AUTH_USER_UUID] || ''
    return {
        adminToken,
        userCustomToken,
        authUserUUID
    }
}

export default function App({ Component, pageProps, adminToken, userCustomToken, authUserUUID }: { adminToken: string, userCustomToken: string, authUserUUID: string } & AppProps<EnterPageProps>) {
    const [enterApi] = useState(() => createEnterApi(adminToken ? { [IMPERSONATION_ADMIN_TOKEN]: adminToken } : {}))
    const [queryClient] = useState(() => createQueryClient())
    const [mswState, setMswState] = useState<'loading' | 'ready' | null>(null)
    const isMockedServerEnabled = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'

    if (isMockedServerEnabled && !mswState) {
        setMswState('loading')
        const mockImport = import('@tests/mocks')
        mockImport.then(({ initMocks }) => initMocks()).then(() => setMswState('ready'))
    }

    if (mswState === 'loading') return null

    // @ts-ignore
    const getLayout = Component.getLayout || ((page) => page)

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                <link rel="manifest" href="/manifest.webmanifest.json" />
                <link rel="icon" href="/favicon.ico" sizes="any" />
                <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
                <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
                <title>Enter App</title>
            </Head>
            {!isMockedServerEnabled && (
                <GoogleTagManagerScript gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string} />
            )}

            <FirebaseCustomAuthentication userCustomToken={userCustomToken} authUserUUID={authUserUUID}>
                <FirebaseAuthenticationProvider>
                    <EnterApiProvider enterApi={enterApi}>
                        <EnterAuthConfigProvider config={SONIC_AUTH_CONFIG}>
                            <QueryClientProvider client={queryClient}>
                                <HydrationBoundary state={pageProps?.dehydratedState}>
                                    <SkeletonTheme baseColor={'#E1E1E4'}>
                                        <Global styles={globalStyles} />
                                        <featureContext.Provider value={{ ...FEATURES }}>
                                            <ScreenSizeProvider>
                                                <UserStatusContextProvider>
                                                    <ToastClient />
                                                    {getLayout(<Component {...pageProps} />)}
                                                </UserStatusContextProvider>
                                            </ScreenSizeProvider>
                                        </featureContext.Provider>
                                        <ModalRoot />
                                        <SelectRoot />
                                    </SkeletonTheme>
                                </HydrationBoundary>
                            </QueryClientProvider>
                        </EnterAuthConfigProvider>
                    </EnterApiProvider>
                </FirebaseAuthenticationProvider>
            </FirebaseCustomAuthentication>
        </>
    )
}
