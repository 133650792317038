import { DialogModal } from './dialogModal'
import Modal, { ModalRoot } from './defaultModal'
import { ConfirmationModal } from './confirmationModal'
import { LoadingModal } from './loadingModal'

export type { DialogModalProps } from './dialogModal'
export type { ConfirmationModalProps } from './confirmationModal'
export type { ModalProps } from './defaultModal'
export type { LoadingModalProps } from './loadingModal'

export { ConfirmationModal, DialogModal, ModalRoot, Modal, LoadingModal }
