import { css } from '@emotion/react'
import { PolymorphicProps } from '../../types/common'
import { TypeScaleVariant, TypeScaleSize, useTheme, EnterTheme } from '../../styles/theme'

export type TextBaseProps = {
    variant?: `${TypeScaleVariant}`
    size?: `${TypeScaleSize}`
}

export type TextProps<T extends React.ElementType> = PolymorphicProps<T, TextBaseProps>

export default function Text<T extends React.ElementType>({
    as,
    children,
    variant = 'body',
    size = 'medium',
    ...rest
}: TextProps<T>) {
    const BaseComponent = as ?? 'span'
    const theme = useTheme()

    return size && variant ? (
        <BaseComponent {...rest} css={getTextStyles(theme, size, variant)}>
            {children}
        </BaseComponent>
    ) : null
}

const getTextStyles = (theme: EnterTheme, size: `${TypeScaleSize}`, variant: `${TypeScaleVariant}`) => css`
    font-size: ${theme.typeScale[variant][size].size}px;
    line-height: ${theme.typeScale[variant][size].height}px;
    font-weight: ${theme.typeScale[variant][size].weight};
    letter-spacing: ${theme.typeScale[variant][size].spacing}px;
    color: ${theme.colors.text.primary.onLight};
`
