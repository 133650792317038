import { createContext, useContext } from 'react'
import colors, { BAUPAL_COLORS } from './tokens/colors'
import { typeScale } from './tokens/text'
import { iconScale } from './tokens/icon'
import { poppins as bodyFont } from './fonts'

/*
 * 1 CORE TOKENS
 * Raw values of the design system responsible for the look of the final product by defining the all values that can be used.
 * These values reusable in SEMANTIC TOKENS. Their names should reflect the values they store and represent.
 * Also called as base values and contains brand colors, fonts, sizes, etc.
 * Naming: namespace-type-
 *
 * 2 SEMANTIC TOKENS
 * Values in this group of tokens reference to CORE TOKENS. Their name describes the intended use of the token.
 * These values are use the most throughout the application.
 * They represent the choices the design system team made in regard to when to use which token.
 *
 * 3 COMPONENT TOKENS (optional)
 * Values in this group reference to SEMANTIC TOKENS and tie them to a specific component value or it CSS property.
 *
 * Example of relationships:
 * core-color-blue: #0070F4;
 * semantic-color-primary: core-color-blue;
 * component-primary-button-bg (or style property): semantic-color-primary;
 * */

const borderRadius: ThemeBorderRadius = {
    dropdown: '10px',
    input: '10px',
    card: '20px',
    toast: '20px',
    button: '18px',
    default: '10px',
    checkbox: '6px',
    modal: '20px',
    tooltip: '18px',
}

const breakpoints: ThemeBreakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1920,
}

const fontSize: ThemeFontSize = {
    h1: '24px',
    h2: '20px',
    h3: '18px',
    h4: '16px',
    h5: '16px',
    h6: '16px',
    subtitle: '14px',
    inputLabel: '10px',
    input: '16px',
    bodyDefault: '14px',
    toast: '14px',
    button: '14px',
}

const fontWeight: ThemeFontWeight = {
    h1: 600,
    h2: 600,
    h3: 600,
    h4: 500,
    h5: 500,
    h6: 500,
    headingDefault: 600,
    subtitle: 400,
    bodyDefault: 400,
    button: 500,
    inputLabel: 400,
    input: 400,
}

const fontFamily: ThemeFontFamily = {
    bodyDefault: bodyFont.style.fontFamily,
}

export type ThemeFontFamily = Partial<Record<'bodyDefault', string>>

const lineHeight: ThemeLineHeight = {
    headingDefault: 1.35,
    bodyDefault: 1.3,
}

const zIndex: ThemeZIndex = {
    default: 0,
    dropdown: 100,
    toast: 200,
    navigation: 300,
    modal: 400,
    overlay: 1000,
    tooltip: 500,
    header: 800,
    footer: 800,
}

const boxShadow: ThemeBoxShadow = {
    light: '0px 4px 16px 4px rgba(14, 14, 14, 0.06)',
    medium: '0px 10px 39px 4px rgba(168, 155, 137, 0.2)',
    dark: '0px 10px 39px 4px rgba(168, 155, 137, 0.3)',
}

export interface EnterTheme extends Theme {
    palette: typeof BAUPAL_COLORS
}

export const THEME = createTheme<EnterTheme>({
    // --- SEMANTIC tokens ---
    colors,
    borderRadius,
    breakpoints,
    fontSize,
    fontWeight,
    lineHeight,
    fontFamily,

    zIndex,
    boxShadow,
    spacing: 8,
    typeScale,
    iconScale,
    // Deprecated, please do not use. This is a core values, use semantic values above instead.
    palette: BAUPAL_COLORS,
})

const themeContext = createContext(THEME)

export const useTheme = () => useContext(themeContext)

export const ThemeProvider = themeContext.Provider

// --- TYPES ---

export function createTheme<T extends Theme = Theme>(
    config: Omit<T, 'spacing' | 'border'> & {
        spacing: number
    }
): T {
    const { spacing, ...rest } = config

    return {
        ...rest,
        spacing: (...values: number[]) => values.map((value) => `${value * spacing}px`).join(' '),
        border: (color: `#${string}`, thickness = 1, variant = 'solid') => `${thickness}px ${variant} ${color}`,
    } as unknown as T
}

export type ThemeBorderRadius = Record<
    'card' | 'button' | 'default' | 'input' | 'checkbox' | 'modal' | 'tooltip' | 'toast' | 'dropdown',
    string
>

export type ThemeZIndex = Record<
    'default' | 'dropdown' | 'toast' | 'navigation' | 'modal' | 'tooltip' | 'header' | 'overlay' | 'footer',
    number
>

export type ThemeHeading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export type ThemeBody =
    | 'text'
    | 'subtitle'
    | 'inputLabel'
    | 'checkboxLabel'
    | 'switchLabel'
    | 'button'
    | 'error'
    | 'input'
    | 'toast'
    | 'tooltip'

export type ThemeFontSize = Partial<Record<ThemeHeading | ThemeBody | 'bodyDefault', string>>

export type ThemeLineHeight = Partial<Record<ThemeHeading | ThemeBody | 'headingDefault' | 'bodyDefault', number>>

export type ThemeFontWeight = Partial<Record<ThemeHeading | ThemeBody | 'headingDefault' | 'bodyDefault', number>>

export type ThemeBoxShadow = Partial<Record<'light' | 'medium' | 'dark', string>>

export type ThemeBreakpoints = {
    xs: 576
    sm: 768
    md: 992
    lg: 1200
    xl: 1920
}

export type ThemeColors = Record<
    // BUTTONS
    | 'onStepChangeButtonDisabled'

    // SIGNALS
    | 'success'
    | 'error'
    | 'info'

    // STATES
    | 'active'
    | 'onActive'
    | 'onInactive'
    | 'selected'
    | 'onSelected'

    // FILTER
    | 'toHover'

    // COMPONENTS
    | 'input'
    | 'onInput',
    string
> & {
    background: Record<'dark' | 'standard' | 'light', string>
} & {
    toast: Record<'info' | 'success' | 'warning' | 'error' | 'default', Record<'background' | 'content', string>>
} & {
    notice: Record<'success', Record<'background' | 'state', string>>
} & {
    [ThemeInteractiveVariant.SECONDARY]: Record<'dark' | 'standard' | 'light', string>
} & {
    [ThemeInteractiveVariant.PRIMARY]: Record<'dark' | 'standard' | 'light', string>
} & {
    tertiary: Record<'dark' | 'standard' | 'light', string>
} & {
    quaternity: Record<'dark' | 'standard' | 'light', string>
} & {
    text: Record<'primary' | 'secondary' | 'tertiary', Record<'onLight' | 'onDark', string>>
} & { interactive: ThemeInteractive } & { feedback: ThemeFeedback }

export enum TypeScaleVariant {
    DISPLAY = 'display',
    HEADLINE = 'headline',
    TITLE = 'title',
    BODY = 'body',
    LABEL = 'label',
}

export enum TypeScaleSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export type ThemeTypeScale = {
    [key in TypeScaleVariant]: {
        [key in TypeScaleSize]: {
            height: number
            size: number
            weight: number
            spacing: number
        }
    }
}

export type ThemeTypeColor = {
    body: string
    description: string
}

export enum IconScaleSize {
    XX_SMALL = 'xxs',
    EXTRA_SMALL = 'xs',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    EXTRA_LARGE = 'xl',
}

export type ThemeIconScale = {
    [key in IconScaleSize]: number
}

export enum ThemeInteractiveVariant {
    SECONDARY = 'secondary',
    PRIMARY = 'primary',
}

export enum ThemeInteractiveState {
    DEFAULT = 'default',
    FOCUS = 'focus',
    HOVER = 'hover',
    DISABLED = 'disabled',
    ACTIVE = 'active',
}

export type ThemeInteractive = {
    [key in ThemeInteractiveVariant]: {
        [key in ThemeInteractiveState]: {
            background: string
            color: string
            border: string
        }
    }
}

export enum ThemeFeedbackVariant {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export type ThemeFeedback = {
    [key in ThemeFeedbackVariant]: {
        background: string
        color: string
        support: string
    }
}

export enum LayoutContentWidth {
    EXTRA_WIDE = '1495px',
    WIDE = '1195px',
    MEDIUM = '957px',
    // This is 751px in the design, but it includes the padding to both sides.
    SMALL_MEDIUM = '783px',
    NARROW = '507px',
}

export interface Theme {
    // --- BASE PROPERTIES ---

    borderRadius: ThemeBorderRadius
    breakpoints: ThemeBreakpoints
    colors: ThemeColors
    fontSize: ThemeFontSize
    fontWeight: ThemeFontWeight
    lineHeight: ThemeLineHeight
    zIndex: ThemeZIndex
    boxShadow: ThemeBoxShadow
    fontFamily: ThemeFontFamily
    typeScale: ThemeTypeScale
    iconScale: ThemeIconScale
    // --- HELPERS ---

    spacing: (...values: number[]) => string
    border: (color: string, thickness?: number, variant?: 'solid') => string
}

export { colors }
