import { css, keyframes } from '@emotion/react'

import { StyleProps, SvgProps } from 'lib/types/propTypes'

type LoadingSpinnerProps = StyleProps & Required<SvgProps>

export const LoadingSpinner = ({ styles, color, width, height }: LoadingSpinnerProps) => {
    return (
        <span
            css={css`
                animation-name: ${rotationKeyframes};
                animation-duration: 0.8s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                height: ${height};
                width: ${width};

                ${styles}
            `}
        >
            <span
                css={css`
                    ${appearAnimation}
                `}
            >
                <svg strokeDasharray={150} strokeWidth="4px" strokeDashoffset={0} viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="32" stroke={color || 'white'} fill="transparent" />
                </svg>
            </span>
        </span>
    )
}

export const rotationKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`

export const appearKeyframes = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

export const appearAnimation = css`
    animation-name: ${appearKeyframes};
    animation-duration: 0.3s;
    animation-timing-function: ease-ease-in-out;
    animation-fill-mode: forwards;
`
