/**
 * CORE COLORS
 * Raw colors of the design system. These values reusable in SEMANTIC COLORS. Their names should reflect the values they store and represent.
 * Please do not use BAUPAL_COLORS in the app. This is the CORE values should be used only in the semantic tokens.
 * */
export const BAUPAL_COLORS = {
    // Deprecated. Use generic instead
    grey: {
        0: '#FBFBFB',
        1: '#F5F5F5',
        2: '#C0C0C0',
        3: '#8E8E8E',
        4: '#717171',
    },

    generic: {
        neutral: {
            white: '#FFF',
            standard: '#F5F5F7',
            light: '#FAFAFB',
            10: '#E1E1E4',
            40: '#A3A5A9',
            60: '#74777F',
            80: '#464A54',
            100: '#181D29',
            black: '#000',
        },
        green: {
            dark: '#6B9070',
            standard: '#5BC06B',
            light: '#E5F5E7',
        },
        red: {
            dark: '#740000',
            standard: '#D93838',
            light: '#F9DEDC',
        },
        yellow: {
            dark: '#A48105',
            standard: '#EDC74C',
            light: '#FFF5D1',
        },
        blue: {
            dark: '#193F61',
            standard: '#165285',
            light: '#EDEDF2',
        },
    },
}
