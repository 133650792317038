import { StyleProps, ChildrenProps } from 'lib/types/propTypes'
import { forwardRef, useMemo } from 'react'
interface HeadingProps extends StyleProps, ChildrenProps {
    level: 1 | 2 | 3 | 4 | 5 | 6
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(({ level, styles, children }, ref) => {
    const Component = useMemo(() => `h${level}` as React.ElementType, [level])

    return (
        <Component ref={ref} css={styles}>
            {children}
        </Component>
    )
})
