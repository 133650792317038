import { createContext, useContext } from 'react'
import { ChildrenProps } from 'lib/types/propTypes'
import { AuthProcess } from 'lib/types/authProcess'

export const encryptPassword = async (message: string): Promise<string> => {
    const JSEncrypt = (await import('jsencrypt')).default
    const jsencrypt = new JSEncrypt()
    if (process.env.NEXT_PUBLIC_IS_KUBERNETES === 'true') {
        jsencrypt.setPublicKey(atob(process.env.NEXT_PUBLIC_BAUPAL_RSA_PUBLIC_KEY_BASE64 as string))
    } else {
        jsencrypt.setPublicKey(process.env.NEXT_PUBLIC_BAUPAL_RSA_PUBLIC_KEY as string)
    }
    return jsencrypt.encrypt(message) || message
}

export interface EnterAuthConfigContext {
    loginUrl: string
    googleClientId: string
    authCookieName: string
}

const EnterAuthContext = createContext<EnterAuthConfigContext>({
    loginUrl: '',
    googleClientId: '',
    authCookieName: '',
})

interface EnterAuthProviderProps extends ChildrenProps {
    config: EnterAuthConfigContext
}

export const EnterAuthConfigProvider = (props: EnterAuthProviderProps) => (
    <EnterAuthContext.Provider value={props.config}>{props.children}</EnterAuthContext.Provider>
)

export const useEnterAuthConfig = () => useContext(EnterAuthContext)

// --- FEATURE CONTEXT ---

export type FeatureFlagType = Record<string, boolean>

export const featureContext = (<T extends FeatureFlagType>() => createContext<T>({ ...({} as T) }))()

export const useFeatureContext = <T extends FeatureFlagType>() =>
    useContext<T>(featureContext as unknown as React.Context<T>)

export const IMPERSONATION_ADMIN_TOKEN = 'impersonation-auth-admin-token' as const
export const IMPERSONATION_ADMIN_TOKEN_EXPIRY = 'impersonation-auth-admin-token-expiry' as const
export const IMPERSONATION_ADMIN_TOKEN_EXPIRY_DURATION = 1000 * 60 * 15 // 15 minutes
export const USER_CUSTOM_TOKEN = 'impersonation-custom-admin-auth-token' as const
export const AUTH_USER_UUID = 'customer-uuid' as const

type IMPERSONATION_ADMIN_TOKEN_TYPE = {
    token: string
    expiry: number
}

export const setImpersonationAdminToken = (token: string, tokenExpiry?: number) => {
    const expiry = tokenExpiry || Date.now() + IMPERSONATION_ADMIN_TOKEN_EXPIRY_DURATION
    window?.sessionStorage?.setItem(
        IMPERSONATION_ADMIN_TOKEN,
        JSON.stringify({ token, expiry } as IMPERSONATION_ADMIN_TOKEN_TYPE)
    )
}
const defaultImpersonationAdminToken = JSON.stringify({
    token: '',
    expiry: 0,
} as IMPERSONATION_ADMIN_TOKEN_TYPE)

export const getImpersonationAdminToken = (): string => {
    const { token, expiry = 0 } = JSON.parse(
        window?.sessionStorage?.getItem(IMPERSONATION_ADMIN_TOKEN) || defaultImpersonationAdminToken
    ) as IMPERSONATION_ADMIN_TOKEN_TYPE

    if (Date.now() < expiry) return token
    return ''
}

export const parseUnprotectedAuthProcess = (rawAuthProcess: unknown): AuthProcess | undefined => {
    if (typeof rawAuthProcess !== 'string') return undefined

    return Object.values(AuthProcess).includes(rawAuthProcess as AuthProcess)
        ? (rawAuthProcess as AuthProcess)
        : undefined
}

export const getUnexpiredToken = (token: string, expiry: string) => {
    if (Date.now() < parseInt(expiry) && token) {
        return token
    }
    return ''
}
