import { useState, useEffect } from 'react'
import { auth, cookieName, maxAgeCookie } from 'lib/utils/firebaseAuthentication'
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { setCookie } from 'cookies-next'
import { datadogRum } from '@datadog/browser-rum'

export type LoggedStateType = {
    isLoggedIn: boolean
    isLoading: boolean
    idToken: string
}

export const useAuthentication = () => {
    const [loggedState, setLoggedState] = useState({
        isLoggedIn: false,
        isLoading: true,
        idToken: '',
    })

    const setLoggedStateWrapper = (newState: LoggedStateType) => {
        if (newState.idToken?.length > 0) {
            setCookie(cookieName, newState.idToken, {
                maxAge: maxAgeCookie,
            })
        }

        setLoggedState({ ...newState })
    }

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken()
                if (!token) return

                setCookie(cookieName, token, {
                    maxAge: maxAgeCookie,
                })
                setLoggedState({
                    isLoggedIn: true,
                    isLoading: false,
                    idToken: token,
                })
            } else {
                setLoggedState({
                    isLoggedIn: false,
                    isLoading: false,
                    idToken: '',
                })
            }
        })
    }, [])

    useEffect(() => {
        onIdTokenChanged(auth, async (user) => {
            try {
                if (user) {
                    const token = await user.getIdToken(true) // Force token refresh
                    if (!token) return

                    setLoggedState({
                        isLoggedIn: true,
                        isLoading: false,
                        idToken: token,
                    })
                    setCookie(cookieName, token, {
                        maxAge: maxAgeCookie,
                    })
                } else {
                    setLoggedState({
                        isLoggedIn: false,
                        isLoading: false,
                        idToken: '',
                    })
                }
            } catch (error) {
                datadogRum.addError(error)
                setLoggedState({
                    isLoggedIn: false,
                    isLoading: false,
                    idToken: '',
                })
            }
        })
    }, [])

    return {
        ...loggedState,
        setLoggedState: setLoggedStateWrapper,
        auth,
    }
}
