import { Auth } from '@firebase/auth'
import { createContext, useContext } from 'react'
import { LoggedStateType } from 'lib/components/authentication/hooks/useAuthentication'

export type AuthenticationContextType = {
    isLoggedIn: boolean
    isLoading: boolean
    auth: Auth | undefined
    idToken: string
    setLoggedState: ((newState: LoggedStateType) => void) | undefined
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
    auth: undefined,
    isLoggedIn: false,
    isLoading: true,
    idToken: '',
    setLoggedState: undefined,
})

export const useAuthenticationContext = () => useContext(AuthenticationContext)
