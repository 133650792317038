import { injectGlobal } from '@emotion/css'
import { EnterError } from 'lib/api/apiFactory'
import { memo, ReactNode, useMemo } from 'react'
import { EnterIcon, IconName } from 'lib/enter-icons/enterIcon'
import {
    toast,
    ToastOptions,
    TypeOptions,
    Id as ToastId,
    ToastContainer,
    ToastContainerProps as BaseToastContainerProps,
    Flip,
} from 'react-toastify'
import { ToastProps } from 'react-toastify/dist/types'
import { css } from '@emotion/react'
import { useTheme } from 'lib/styles/theme'
import { useDesktop } from '../context/screenSizeContext'
import Text from '../components/text/text'
import { datadogRum } from '@datadog/browser-rum'

interface ToastMessageContainerProps {
    message: string
    type: Extract<TypeOptions, 'success' | 'warning' | 'error'>
    iconName?: IconName
}

const ToastMessageContainer = ({ message, type, iconName }: ToastMessageContainerProps) => {
    const theme = useTheme()

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                column-gap: ${theme.spacing(2)};
            `}
        >
            <EnterIcon
                name={iconName || IconName.InfoCircle}
                styles={css`
                    color: ${theme.colors.feedback[type].color};
                `}
            />
            <Text variant="label" size="large">
                {message}
            </Text>
        </div>
    )
}

export const toastErrors = (error: EnterError) => {
    datadogRum.addError(error)
    if (!error || !error.errors) return sendToast({ message: 'Oops, an unknown error occurred', type: 'error' })
    error.errors.forEach((error) => sendToast({ message: error.msg, type: 'error' }))
}

export const toastSuccessMessage = (message?: string) => {
    if (typeof message !== 'string') return
    sendToast({ message, type: 'success' })
}

export interface ToastContainerProps {
    children: ReactNode
    closeToast?: () => void
    toastProps?: ToastProps
    type: Extract<TypeOptions, 'success' | 'warning' | 'error'>
    iconName?: IconName
}

/**
 * This is a wrapper around react-toastify that provides a consistent API for sending toasts.
 * This function is typically called once per project, in order to configure the toasts.
 *
 * @param message - The message to display in the toast
 * @param type - The type of toast to display
 * @param toastOptions - The options to pass to the toast
 *
 */
type CreateSendToast = (
    renderChildren?: (props: ToastMessageContainerProps) => ReactNode,
    Component?: (props: ToastContainerProps) => Element
) => ({
    message,
    type,
    iconName,
    options,
}: {
    message: string
    type: Extract<TypeOptions, 'success' | 'warning' | 'error'>
    iconName?: IconName
    options?: Omit<ToastOptions, 'type'>
}) => ToastId

export const createSendToast: CreateSendToast =
    (renderChildren) =>
    ({ message, type, iconName, options }) =>
        toast(
            (props) => (
                <ToastComponent type={type} {...props}>
                    {renderChildren ? renderChildren({ message, type, iconName }) : message}
                </ToastComponent>
            ),
            {
                ...options,
            }
        )

export const ToastComponent = ({ children, ...props }: ToastContainerProps) => {
    const theme = useTheme()

    return (
        <div
            css={css`
                border-radius: ${theme.borderRadius.toast || theme.borderRadius.default};
                background-color: ${theme.colors.feedback[props.type].background};
                z-index: ${theme.zIndex.toast};
                font-weight: ${theme.fontWeight.toast || theme.fontWeight.bodyDefault};
                line-height: ${theme.lineHeight.toast || theme.lineHeight.bodyDefault};
                padding: ${theme.spacing(2)} ${theme.spacing(2)};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                min-height: 56px;
            `}
        >
            {children}
        </div>
    )
}

export type ToastClientProps = Omit<BaseToastContainerProps, 'toastStyle'>

export const ToastClient = memo((props: ToastClientProps) => {
    const theme = useTheme()
    const { isDesktop } = useDesktop()

    const position = useMemo(() => (isDesktop ? 'top-right' : 'top-center'), [isDesktop])

    injectGlobal`
      .Toastify__close-button {
        display: none;
      }

      .Toastify__toast {
        min-height: unset;
        padding: 0;
        background: transparent !important;
        border-radius: ${theme.borderRadius.toast || theme.borderRadius.default};
        margin: ${theme.spacing(2)};
      }

        .Toastify__toast-body {
            padding: 0;
            height: fit-content;
        }
    `

    return (
        <ToastContainer
            transition={Flip}
            theme="colored"
            autoClose={5000}
            limit={3}
            {...{
                ...props,
                position,
            }}
        />
    )
})

export const sendToast = createSendToast(({ message, type, iconName }) => (
    <ToastMessageContainer message={message} type={type} iconName={iconName} />
))
