import interactiveColors from './interactive'
import semanticColors from './semantic'
import feedbackColors from './feedback'
import { BAUPAL_COLORS } from './palette'

/**
 * SEMANTIC COLORS
 * Values in this group of tokens reference to BAUPAL_COLORS. These name describes the intended use of the core colors.
 * These values are use the most throughout the application.
 * They represent the choices the design system team made in regard to when to use which token.
 * */
export default {
    input: BAUPAL_COLORS.generic.neutral.standard,

    onInput: BAUPAL_COLORS.generic.neutral[100],

    error: BAUPAL_COLORS.generic.red.standard,
    success: BAUPAL_COLORS.generic.green.standard,
    info: '#b6b6c2',

    onStepChangeButtonDisabled: '#A2A2A2',

    selected: BAUPAL_COLORS.generic.green.light,
    onSelected: BAUPAL_COLORS.generic.green.standard,

    onInactive: BAUPAL_COLORS.generic.neutral[100],

    active: BAUPAL_COLORS.generic.green.standard,
    onActive: BAUPAL_COLORS.generic.neutral.white,

    toHover: 'brightness(0.97)',

    toast: {
        success: { background: '#E5F5E7', content: '#464A54' },
        error: { background: '#F9DEDC', content: '#464A54' },
        warning: { background: '#FFF5D1', content: '#464A54' },
        info: { background: '#FFFFFF', content: '#464A54' },
        default: { background: '#FFFFFF', content: '#000000' },
    },

    notice: {
        success: { background: BAUPAL_COLORS.generic.green.light, state: BAUPAL_COLORS.generic.green.standard },
    },
    ...semanticColors,
    interactive: interactiveColors,
    feedback: feedbackColors,
}

export { BAUPAL_COLORS, semanticColors, interactiveColors, feedbackColors }
