import { BAUPAL_COLORS } from './palette'

export default {
    background: {
        dark: BAUPAL_COLORS.generic.neutral[100],
        standard: BAUPAL_COLORS.generic.neutral.standard,
        light: BAUPAL_COLORS.generic.neutral.white,
    },
    primary: {
        dark: BAUPAL_COLORS.generic.neutral[100],
        standard: BAUPAL_COLORS.generic.neutral.standard,
        light: BAUPAL_COLORS.generic.neutral.light,
    },
    secondary: {
        dark: BAUPAL_COLORS.generic.green.dark,
        standard: BAUPAL_COLORS.generic.green.standard,
        light: BAUPAL_COLORS.generic.green.light,
    },
    tertiary: {
        dark: BAUPAL_COLORS.generic.yellow.dark,
        standard: BAUPAL_COLORS.generic.yellow.standard,
        light: BAUPAL_COLORS.generic.yellow.light,
    },
    quaternity: {
        dark: BAUPAL_COLORS.generic.blue.dark,
        standard: BAUPAL_COLORS.generic.blue.standard,
        light: BAUPAL_COLORS.generic.blue.light,
    },
    text: {
        primary: {
            onLight: BAUPAL_COLORS.generic.neutral[100],
            onDark: BAUPAL_COLORS.generic.neutral.white,
        },
        secondary: {
            onLight: BAUPAL_COLORS.generic.neutral[60],
            onDark: BAUPAL_COLORS.generic.neutral.white,
        },
        tertiary: {
            onLight: BAUPAL_COLORS.generic.neutral[40],
            onDark: BAUPAL_COLORS.generic.neutral.white,
        },
    },
}
