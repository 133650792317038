import { ThemeIconScale } from '../theme'

export const iconScale: ThemeIconScale = {
    xxs: 12,
    xs: 16,
    sm: 18,
    md: 24,
    lg: 32,
    xl: 48,
}
