import { css } from '@emotion/react'
import { MotionProps } from 'framer-motion'

export const getResetBrowserNumberInputIncrementButtonsCss = () => css`
    /* Chrome, Safari, Edge, Opera */

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */

    input[type='number'] {
        -moz-appearance: textfield;
    }
`

export const OPACITY_TRANSITION: MotionProps = {
    initial: { opacity: 0 },
    animate: {
        opacity: 1,
        transition: {
            opacity: {
                duration: 0.4,
            },
        },
    },
    exit: {
        opacity: 0,
        transition: {
            opacity: {
                duration: 0.05,
            },
        },
    },
}

export const getOpacityTransitionWithDelay: (delay: number) => MotionProps = (delay) => ({
    ...OPACITY_TRANSITION,
    animate: {
        opacity: 1,
        transition: {
            opacity: {
                duration: 0.4,
            },
            delay,
        },
    },
})

export enum DelayFactor {
    List = 0.15,
}

export function getBaseDelay<K, T extends [K, number][]>(order: T, target: K) {
    const targetIndex = order.findIndex(([name]) => name === target)

    return order.reduce((acc, [, delay], index) => {
        if (index > targetIndex) return acc
        return acc + delay
    }, 0)
}
