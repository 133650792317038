import { Variants } from 'framer-motion'

export type StepDirection = 'prev' | 'next' | 'skip'

export enum FieldType {
    TEXT_INPUT = 'text-input',
    CHECKBOX_SELECT = 'checkbox-select',
    CHECKBOX = 'checkbox',
    SIGNUP = 'signup',
    MULTI_FIELDS = 'multi-fields',
    CALENDLY = 'calendly',
}

export enum FieldHelpType {
    TITLE = 'title',
    PARAGRAPH = 'paragraph',
    IMAGE = 'image',
}

export const STEP_ANIMATION_VARIANTS: Variants = {
    initial: (stepDirection: StepDirection) => {
        return {
            y: stepDirection === 'next' || stepDirection === 'skip' ? -100 : 100,
            opacity: 0,
        }
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            opacity: { duration: 0.3 },
            y: {
                type: 'spring',
                stiffness: 200,
                damping: 20,
            },
        },
    },
    exit: (stepDirection: StepDirection) => {
        return {
            y: stepDirection === 'prev' ? -100 : 100,
            opacity: 0,
            transition: {
                opacity: { duration: 0.1 },
                y: {
                    type: 'spring',
                    stiffness: 200,
                    damping: 20,
                },
            },
        }
    },
}

// @TODO Use theme spacing
export const CONTENT_BASE_PADDING = 16
export const CONTENT_DESKTOP_TOP_PADDING = 36

export enum AppEntryPage {
    LOGIN = 'login',
    DASHBOARD = 'dashboard',
    UNAUTHENTICATED_FORM = 'unauthenticatedForm',
}
