export interface GTMDataLayerProps {
    gtmEvent?: GTMEvent
    gtmValue?: GTMValue
    gtmData?: Record<string, any> | string
}

export enum GTMEvent {
    UI_BUTTON_INTERACTION = 'ui_button_interaction',
    UI_DROPDOWN_INTERACTION = 'ui_dropdown_interaction',
    USER_AUTH = 'user_auth',
    USER_INIT = 'user_init',
    GENERATE_LEAD = 'generate_lead',
}

export const GTMSessionDataStorageKey = 'gtmSessionData'

export enum GTMValue {
    SUBMIT_LEAD_DATA = 'submit_lead_data',
    REQUEST_APPOINTMENT = 'request_appointment',
    REQUEST_CONSULTATION = 'request_consultation',
    TOOLTIP_QUESTIONMARK = 'tooltip_questionmark',
    BACK_BUTTON = 'back_button',
    PRICE_HUBBLE_CTA = 'pricehubble_CTA',
    PRICE_HUBBLE_ROBO_SURVEY = 'pricehubble_robo_survey',
    PRICE_LOCATION_INFORMATION = 'pricehubble_location_information',
    PRICE_LOCATION_INFORMATION_SUBMIT = 'pricehubble_location_information_submit',

    NEXT_STEP_FOR_YOU_CTA = 'next_step_for_you_CTA',
    ROBO_SURVEY_FOR_YOU_CTA = 'robo_survey_for_you_CTA',
    INSTALLATION_PARTNER_FOR_YOU_CTA = 'installation_partner_for_you_CTA',
    ISFP_FOR_YOU_CTA = 'isfp_for_you_CTA',
    SUBSIDY_SERVICE_FOR_YOU_CTA = 'subsidy_service_for_you_CTA',
    KNOWLEDGE_VIDEO_FOR_YOU_PLAY_BUTTON = 'knowledge_video_for_you_play_button',
    ROBO_SURVEY_ANALYSIS_CTA = 'robo_survey_analysis_CTA',
    ISFP_DOCUMENTS_CTA = 'isfp_documents_CTA',
    INSTALLATION_PARTNER_DISCOVERY_CTA = 'installation_partner_discovery_CTA',

    OPS_DROPPED_OFF_CUSTOMERS_FILTER_GENERAL = 'ops_dropped_off_customers_filter_general',
    OPS_DROPPED_OFF_CUSTOMERS_FILTER_SLI_STAGE_ALL = 'ops_dropped_off_customers_filter_sli_stage_all',
    OPS_DROPPED_OFF_CUSTOMERS_FILTER_SLI_STAGE_SPECIFIC = 'ops_dropped_off_customers_filter_sli_stage_specific',
    OPS_CLICK_HDS_CATEGORY_SURVEY = 'ops_click_hds_category_survey',
    SUBMIT_SURVEY = 'submit_survey',

    LOGOUT = 'logout',
    LOGIN = 'login',
    SIGN_UP = 'sign_up',
}
