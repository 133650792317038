import { EmptyObject } from 'lib/types/advanced'

import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import axios, {
    AxiosError,
    AxiosInterceptorManager,
    AxiosRequestConfig,
    InternalAxiosRequestConfig,
    AxiosRequestHeaders,
    AxiosResponse,
    AxiosInstance,
} from 'axios'
import { InfiniteData, QueryClient, QueryKey, UseInfiniteQueryOptions, UseQueryOptions } from '@tanstack/react-query'
import { getCookie } from 'cookies-next'

import { IMPERSONATION_ADMIN_TOKEN, getImpersonationAdminToken } from '../utils/authentication'

/**
 * This is what a response from the Enter API looks like if the request was successful.
 *
 * @param data The data directly associated with your request
 * @param meta Meta information about the data, e.g. pagination.
 */
export interface EnterResponse<TData, TMeta = undefined> {
    data: TData
    meta: TMeta
}

/**
 * This is what the response from the Enter API looks like, if there was an error.
 *
 * Note: It is very important to allow EnterError to be undefined, as there won't be a structured
 * error response if the server has an internal error.
 */
export type EnterError =
    | undefined
    | {
          errors: {
              msg: string
              location: 'body' | 'header' | 'query' | 'params'
              param: string
              value: string
          }[]
      }

/**
 * Typical GET Request that is set up with Enter API types.
 *
 * @argument path The path to the api endpoint
 * @argument headers Optional headers to be sent with the request.
 *
 * @returns Promise that, in case it was successful, returns a Enter response @see EnterResponse
 */
export type EnterApiGet = <TData, TMeta = undefined>(
    path: string,
    headers?: AxiosRequestHeaders,
    config?: AxiosRequestConfig,
    crewBaseUrl?: string
) => Promise<EnterResponse<TData, TMeta>>

/**
 * Typical POST Request that is set up with Enter API types.
 *
 * @argument path The path to the api endpoint
 * @argument payload The payload that the server expects in the request.
 * @argument headers Optional headers to be sent with the request.
 *
 * @returns Promise that, in case it was successful, returns a Enter response @see EnterResponse
 */
export type EnterApiPost = <TData, TPayload, TMeta = undefined>(
    path: string,
    payload: TPayload,
    headers?: AxiosRequestHeaders,
    crewBaseUrl?: string
) => Promise<EnterResponse<TData, TMeta>>

/**
 * Typical PATCH Request that is set up with Enter API types.
 *
 * @argument path The path to the api endpoint
 * @argument payload The payload that the server expects in the request.
 * @argument headers Optional headers to be sent with the request.
 *
 * @returns Promise that, in case it was successful, returns a Enter response @see EnterResponse
 */
export type EnterApiPatch = <TData, TPayload, TMeta = undefined>(
    path: string,
    payload: TPayload,
    headers?: AxiosRequestHeaders,
    crewBaseUrl?: string
) => Promise<EnterResponse<TData, TMeta>>

/**
 * Typical PUT Request that is set up with Enter API types.
 *
 * @argument path The path to the api endpoint
 * @argument payload The payload that the server expects in the request.
 * @argument headers Optional headers to be sent with the request.
 *
 * @returns Promise that, in case it was successful, returns a Enter response @see EnterResponse
 */
export type EnterApiPut = <TData, TPayload, TMeta = undefined>(
    path: string,
    payload: TPayload,
    headers?: AxiosRequestHeaders,
    crewBaseUrl?: string
) => Promise<EnterResponse<TData, TMeta>>

/**
 * Typical DELETE Request that is set up with Enter API types.
 *
 * @argument path The path to the api endpoint
 * @argument headers Optional headers to be sent with the request.
 *
 * @returns Promise that, in case it was successful, returns a Enter response @see EnterResponse
 */
export type EnterApiDelete = <TData, TPayload, TMeta = undefined>(
    path: string,
    payload: TPayload,
    headers?: AxiosRequestHeaders,
    crewBaseUrl?: string
) => Promise<EnterResponse<TData, TMeta>>

/**
 * The Enter API instance provides the engineer with HTTP methods to access the Enter API.
 *
 * @param get Send a GET request to the Enter API
 * @param post Send a POST request to the Enter API
 * @param patch Send a PATCH request to the Enter API
 * @param put Send a PUT request to the Enter API
 * @param delete Send a DELETE request to the Enter API
 */
export interface EnterApi {
    axios: AxiosInstance
    get: EnterApiGet
    post: EnterApiPost
    patch: EnterApiPatch
    put: EnterApiPut
    delete: EnterApiDelete
}

/**
 * Create a Enter API instance.
 *
 * This is typically done once on the client, and multiple times (per page to be exact) on the NextJS server.
 *
 * @argument nextReqCookies If you are on the server, you must pass the NextJS cookies object
 *
 * @returns The Enter API instance @see EnterApi
 */
export type CreateEnterApi<SSR extends boolean> = (
    nextReqCookies: SSR extends false ? void : NextApiRequestCookies
) => EnterApi

/**
 * Configuration for your Enter API Instance factory.
 *
 * This is typically done once per project.
 *
 * @param baseUrl The api base url for Enter API calls
 * @param axiosConfig Additional standard configuration for the api instance.
 * @param authCookieName The name of the cookie that holds the access token
 * @param responseMiddleware Standard axios response interceptor use function with the args split into params
 * @param requestMiddleware Standard axios request interceptor use function with the args split into params
 */
export interface EnterApiFactoryConfig {
    baseUrl: string
    axiosConfig?: AxiosRequestConfig
    authCookieName: string
    responseMiddleware?: {
        onFulfilled?: Parameters<AxiosInterceptorManager<AxiosResponse>['use']>[0]
        onRejected?: Parameters<AxiosInterceptorManager<AxiosResponse>['use']>[1]
        options?: Parameters<AxiosInterceptorManager<AxiosResponse>['use']>[2]
    }
    requestMiddleware?: {
        onFulfilled?: Parameters<AxiosInterceptorManager<InternalAxiosRequestConfig>['use']>[0]
        onRejected?: Parameters<AxiosInterceptorManager<AxiosRequestConfig>['use']>[1]
        options?: Parameters<AxiosInterceptorManager<AxiosRequestConfig>['use']>[2]
    }
}

/**
 * Configurable factory for your Enter API instance.
 *
 * @argument config Configuration for your Enter API instance creator @see EnterApiFactoryConfig
 *
 * @returns Enter API instance creator @see CreateEnterApi
 */
export type EnterApiFactory = <SSR extends boolean>(config: EnterApiFactoryConfig) => CreateEnterApi<SSR>

const getAxiosInstance = (props: EnterApiFactoryConfig & { crewBaseUrl?: string }) => {
    const { crewBaseUrl, baseUrl, axiosConfig, responseMiddleware, requestMiddleware } = props
    const api = axios.create({
        baseURL: crewBaseUrl || baseUrl,
        headers: {
            'Content-Type': 'application/json',
        },
        ...axiosConfig,
    })

    if (requestMiddleware) {
        api.interceptors.request.use(
            requestMiddleware.onFulfilled,
            requestMiddleware.onRejected,
            requestMiddleware.options
        )
    }

    if (responseMiddleware) {
        api.interceptors.response.use(
            responseMiddleware.onFulfilled,
            responseMiddleware.onRejected,
            responseMiddleware.options
        )
    }
    return api
}

export const enterApiFactory: EnterApiFactory = (props) => {
    const { authCookieName } = props
    const api = getAxiosInstance(props)

    return (nextReqCookies) => {
        const getHeader = (headers?: AxiosRequestHeaders): AxiosRequestConfig => {
            let token = getCookie(authCookieName)

            if (nextReqCookies?.[authCookieName]) {
                token = nextReqCookies?.[authCookieName]
            }

            if (typeof window !== 'undefined') {
                const adminToken = getImpersonationAdminToken()
                if (adminToken) {
                    token = adminToken
                }
            }

            if (nextReqCookies?.[IMPERSONATION_ADMIN_TOKEN]) {
                token = nextReqCookies[IMPERSONATION_ADMIN_TOKEN]
            }

            return {
                headers: {
                    ...(token
                        ? {
                              'x-id-token': token,
                          }
                        : {}),
                    ...(headers || {}),
                },
            }
        }

        return {
            axios: api,

            get: async (path, headers, config = {}, crewBaseUrl) => {
                const axiosApi = getAxiosInstance({
                    ...props,
                    crewBaseUrl,
                })
                return axiosApi
                    .get(path, { ...getHeader(headers), ...config })
                    .then((response) => {
                        return response.data
                    })
                    .catch((error: AxiosError<EnterError>) => Promise.reject(error.response?.data))
            },
            post: async (path, payload, headers, crewBaseUrl) => {
                const axiosApi = getAxiosInstance({
                    ...props,
                    crewBaseUrl,
                })
                return axiosApi
                    .post(path, payload, getHeader(headers))
                    .then((response) => response.data)
                    .catch((error: AxiosError<EnterError>) => Promise.reject(error.response?.data))
            },
            patch: async (path, payload, headers, crewBaseUrl) => {
                const axiosApi = getAxiosInstance({
                    ...props,
                    crewBaseUrl,
                })
                return axiosApi
                    .patch(path, payload, getHeader(headers))
                    .then((response) => response.data)
                    .catch((error: AxiosError<EnterError>) => Promise.reject(error.response?.data))
            },
            put: async (path, payload, headers, crewBaseUrl) => {
                const axiosApi = getAxiosInstance({
                    ...props,
                    crewBaseUrl,
                })
                return axiosApi
                    .put(path, payload, getHeader(headers))
                    .then((response) => response.data)
                    .catch((error: AxiosError<EnterError>) => Promise.reject(error.response?.data))
            },
            delete: async (path, payload, headers, crewBaseUrl) => {
                const axiosApi = getAxiosInstance({
                    ...props,
                    crewBaseUrl,
                })
                return axiosApi
                    .delete(path, { data: payload, ...getHeader(headers) })
                    .then((response) => response.data)
                    .catch((error: AxiosError<EnterError>) => Promise.reject(error.response?.data))
            },
        }
    }
}

export const createQueryClient = () => {
    const qc = new QueryClient()

    qc.setDefaultOptions({
        queries: {
            staleTime: Infinity,
            retry: 0,
        },
    })

    return qc
}

/**
 * Options that configures the useEnterQuery hook.
 */
export type EnterQueryOptions<TData, TQueryKey extends QueryKey = QueryKey> = Omit<
    UseQueryOptions<TData, EnterError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
>

/**
 * Options that configure the useEnterInfiniteQuery hook.
 */
export type EnterInfiniteQueryOptions<
    TQueryFnData,
    TData = InfiniteData<TQueryFnData>,
    TQueryKey extends QueryKey = QueryKey,
> = Omit<UseInfiniteQueryOptions<TQueryFnData, EnterError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>

export type ApiInclude<K extends string | number | symbol | null> = K extends string | number | symbol
    ? Partial<Record<K, boolean>>
    : EmptyObject

/**
 *
 * @param pathname
 * @param include
 * @param additionalQuery
 *
 * @description This is a function that handles the creation of a URI for the Enter API.
 * It takes care of the include query parameter and additional query parameters.
 * Include Parameters let you include certain data in an exisiting request.
 */
export const createApiUri = <K extends string | number | symbol | null>(
    pathname: string,
    include: ApiInclude<K>,
    additionalQuery: Record<string, string | number | boolean | string[]> = {}
) => {
    const includedKeys =
        include === null
            ? []
            : Object.entries(include).reduce((acc, [key, value]) => {
                  if (value) {
                      return [...acc, key as K]
                  }

                  return acc
              }, [] as K[])

    const queryStart = includedKeys.reduce((acc, key, index) => {
        const separator = index === 0 ? 'include=' : ','

        return `${acc}${separator}${String(key)}`
    }, '?')

    const queryEnd = Object.entries(additionalQuery).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            return `${acc}&${key}=${value.join(`&${key}=`)}`
        }

        if (value !== undefined && value !== null) {
            return `${acc}&${key}=${value}`
        }

        return acc
    }, '')

    if (queryStart === '?' && !queryEnd) return pathname

    return `${pathname}${queryStart}${queryEnd}`
}
