import { AnyObject } from 'lib/types/advanced'
import { omit } from 'lib/utils/helpers'
import Head from 'next/head'
import { NextRouter, useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

/**
 * This hook will return true if the component is mounted directly on the client (no SSR) and false if it is not.
 */
export const useIsBrowser = () => {
    const [isBrowser, setIsBrowser] = useState(false)

    useEffect(() => setIsBrowser(true), [])

    return isBrowser
}

type UseCounterArgs = {
    start?: number
    end?: number
    intervalLength?: number
    startCounter?: boolean
    resetCounter?: boolean
    onEnd?: () => void
}

export const useCounter = ({
    start = 0,
    end = undefined,
    intervalLength,
    startCounter,
    resetCounter,
    onEnd,
}: UseCounterArgs) => {
    const [count, setCount] = useState(start)

    useEffect(() => {
        if (!startCounter) return

        if (count === end) {
            if (resetCounter) {
                setCount(start)
                return
            }
            return onEnd?.()
        }

        const interval = setInterval(() => {
            setCount(count + 1)
        }, intervalLength)

        return () => clearInterval(interval)
    }, [count, intervalLength, end, startCounter, resetCounter, onEnd])

    return count
}

export const useErrors = <T extends AnyObject>(initialState: Record<keyof T, string | null>) => {
    const [errors, unsafeSetErrors] = useState(initialState)

    const setError = useCallback(
        (key: keyof T, error: string | null) => {
            unsafeSetErrors((prev) => ({ ...prev, [key]: error }))
        },
        [unsafeSetErrors]
    )

    const setErrors = useCallback((errors: Partial<Record<keyof T, string | null>>) => {
        unsafeSetErrors((prev) => ({ ...prev, ...errors }))
    }, [])

    const clearErrors = useCallback(() => {
        unsafeSetErrors((prevErrors) =>
            Object.keys(prevErrors).reduce(
                (acc, key) => ({ ...acc, [key]: null }),
                {} as Record<keyof T, string | null>
            )
        )
    }, [])

    const clearError = useCallback((key: keyof T) => {
        unsafeSetErrors((prevErrors) => ({ ...prevErrors, [key]: null }))
    }, [])

    const makeClearError = useCallback(
        (key: keyof T) => () => {
            clearError(key)
        },
        [clearError]
    )

    return useMemo(
        () => ({
            errors,
            clearError,
            makeClearError,
            setError,
            setErrors,
            clearErrors,
        }),
        [errors, setError, makeClearError, setErrors, clearErrors, clearError]
    )
}

export const useBoolean = (initialValue: boolean) => {
    const [value, setValue] = useState(initialValue)

    const setTrue = useCallback(() => setValue(true), [])
    const setFalse = useCallback(() => setValue(false), [])
    const toggle = useCallback(() => setValue((prev) => !prev), [])

    return useMemo(() => ({ value, setValue, setTrue, setFalse, toggle }), [value, setValue, setTrue, setFalse, toggle])
}

export type MediaQuery =
    | `(${'min-width' | 'max-width'}: ${number}px)`
    | `(min-width: ${number}px) and (max-width: ${number}px)`

export const useMediaQuery = (mediaQuery: MediaQuery) => {
    const [matches, setMatches] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        const media = window.matchMedia(mediaQuery)

        if (media.matches !== matches) {
            setMatches(media.matches)
        }

        const listener = () => setMatches(media.matches)

        window.addEventListener('resize', listener)

        return () => window.removeEventListener('resize', listener)
    }, [matches, mediaQuery])

    return matches
}

export type UseWindowBreakpointArgs =
    | {
          breakpoint: number
          direction: 'up' | 'down'
      }
    | {
          breakpoint: [number, number]
          direction: 'between'
      }

export const useWindowBreakpoint = ({ breakpoint, direction }: UseWindowBreakpointArgs) => {
    const mediaQuery = useMemo(
        (): MediaQuery =>
            direction === 'between'
                ? `(min-width: ${breakpoint[0]}px) and (max-width: ${breakpoint[1]}px)`
                : direction === 'up'
                  ? `(min-width: ${breakpoint}px)`
                  : `(max-width: ${breakpoint}px)`,
        [direction, breakpoint]
    )

    const matches = useMediaQuery(mediaQuery)

    return useMemo(() => ({ matches, mediaQuery: `@media screen and ${mediaQuery}` }), [matches, mediaQuery])
}

export type RouteTransitionOptions = Parameters<NextRouter['push']>[2]

export const useQueryParam = <T extends string>(urlQueryKey: string) => {
    const router = useRouter()

    const value = useMemo(() => {
        const queryValue = router.query[urlQueryKey]

        if (typeof queryValue === 'string') {
            return queryValue as T
        }

        return undefined
    }, [router, urlQueryKey])

    const setValue = useCallback(
        (newValue: T | undefined, replace?: boolean, transitionOptions?: RouteTransitionOptions) => {
            const injectedQuery = newValue === undefined ? undefined : { [urlQueryKey]: newValue }

            if (replace) {
                return router.replace(
                    {
                        pathname: router.pathname,
                        query: {
                            ...omit(router.query, urlQueryKey),
                            ...injectedQuery,
                        },
                    },
                    undefined,
                    transitionOptions
                )
            } else {
                return router.push(
                    {
                        pathname: router.pathname,
                        query: {
                            ...omit(router.query, urlQueryKey),
                            ...injectedQuery,
                        },
                    },
                    undefined,
                    transitionOptions
                )
            }
        },
        [router, urlQueryKey]
    )

    return [value, setValue] as const
}

// Fetch an image in the background
// @see https://github.com/eykrehbein/typesafe/blob/master/public/articles/fetch-images-with-a-react-hook.md
export const useImage = (src: string) => {
    const [hasLoaded, setHasLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [hasStartedInitialFetch, setHasStartedInitialFetch] = useState(false)

    useEffect(() => {
        setHasStartedInitialFetch(true)
        setHasLoaded(false)
        setHasError(false)

        // Here's where the magic happens.
        const image = new Image()
        image.src = src

        const handleError = () => {
            setHasError(true)
        }

        const handleLoad = () => {
            setHasLoaded(true)
            setHasError(false)
        }

        image.onerror = handleError
        image.onload = handleLoad

        return () => {
            image.removeEventListener('error', handleError)
            image.removeEventListener('load', handleLoad)
        }
    }, [src])

    return { hasLoaded, hasError, hasStartedInitialFetch }
}

export const useRedirect = () => {
    const router = useRouter()

    const makeHandleRedirect = useCallback(
        (redirectLink: string, routerOptions: 'push' | 'replace' = 'push') =>
            () =>
                routerOptions === 'replace' ? router.replace(redirectLink) : router.push(redirectLink),
        [router]
    )

    return [makeHandleRedirect] as const
}

/**
 *
 * @param refs
 * @returns A ref callback with all the merged refs.
 */
export function useMergeRefs<TType>(
    ...refs: (React.MutableRefObject<TType | null> | ((instance: TType) => void) | null)[]
) {
    const cache = useRef(refs)

    useEffect(() => {
        cache.current = refs
    }, [refs])

    return useCallback(
        (value: TType) => {
            for (const ref of cache.current) {
                if (ref == null) continue

                if (typeof ref === 'function') {
                    ref(value)
                } else {
                    ref.current = value
                }
            }
        },
        [cache]
    )
}

export const useClickOutside = <T extends HTMLElement>(callback: (event: MouseEvent) => void) => {
    const ref = useRef<T>(null)

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback(event)
            }
        },
        [callback]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [handleClickOutside])

    return ref
}

export const usePageTitle = (propsTitle: unknown) => {
    const [title, setTitle] = useState<string | undefined>(typeof propsTitle === 'string' ? propsTitle : undefined)

    useEffect(() => {
        if (typeof propsTitle !== 'string') return

        setTitle(propsTitle)
    }, [propsTitle])

    return (
        <Head>
            <title>{title}</title>
        </Head>
    )
}
