import { css } from '@emotion/react'
import { useTheme } from 'lib/styles/theme'
import { Modal, ModalProps } from 'lib/components/modal'
import { LoadingIndicator } from 'lib/components/loading/loadingIndicator'
import { omit } from 'lib/utils/helpers'
import { PropsWithChildren } from 'react'

export type LoadingModalProps = Omit<ModalProps, 'children' | 'isOpen'>

export const LoadingModal = ({ ...restLoadingModalProps }: PropsWithChildren<LoadingModalProps>) => {
    // --- STATE ---

    const theme = useTheme()

    // --- RENDER ---

    return (
        <Modal
            isOpen={true}
            styleType="flat"
            modalStyles={css`
                box-shadow: none;

                ${restLoadingModalProps.modalStyles}
            `}
            overlayStyles={css`
                background-color: ${theme.colors.background.light};

                ${restLoadingModalProps.overlayStyles}
            `}
            {...omit(restLoadingModalProps, 'styleType', 'modalStyles', 'overlayStyles')}
        >
            <LoadingIndicator />
        </Modal>
    )
}
