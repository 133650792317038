export enum AuthProcess {
    LOGIN_CHECK = 'login',
    LOGIN_MAGIC_LINK = 'login-magic-link',
    LOGIN_PASSWORD = 'login-password',

    RESET_REQUEST = 'reset-request',
    RESET_PASSWORD = 'reset-password',

    CHANGE_PASSWORD = 'change-password',
    VALIDATE_PREVIOUS_PASSWORD = 'validate-previous-password',

    SET_PASSWORD = 'set-password',
    SIGNUP = 'signup',
    EMAIL_VERIFICATION = 'email-verification',
}
