import { createContext, ReactNode, useContext } from 'react'
import { EnterApi, EnterQueryOptions, EnterError } from './apiFactory'
import {
    MutationFunction,
    MutationKey,
    QueryFunction,
    QueryKey,
    UseInfiniteQueryOptions,
    useMutation,
    UseMutationOptions,
    useQuery,
    InfiniteData,
} from '@tanstack/react-query'

/**
 * Options that configure the useEnterInfiniteQuery hook.
 */
export type EnterInfiniteQueryOptions<
    TQueryFnData,
    TData = InfiniteData<TQueryFnData>,
    TQueryKey extends QueryKey = QueryKey,
> = Omit<UseInfiniteQueryOptions<TQueryFnData, EnterError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>

const EnterApiContext = createContext<EnterApi>({
    axios: {} as any,
    get: () => Promise.reject('You forgot to wrap your app with the enter api provider.'),
    post: () => Promise.reject('You forgot to wrap your app with the enter api provider.'),
    patch: () => Promise.reject('You forgot to wrap your app with the enter api provider.'),
    put: () => Promise.reject('You forgot to wrap your app with the enter api provider.'),
    delete: () => Promise.reject('You forgot to wrap your app with the enter api provider.'),
})

/**
 * These props must be passed to the Enter APi provider
 *
 * @param children The app that is wrapped by this provider
 * @param enterApi Enter API instance @see EnterApi
 */
export interface EnterApiProviderProps {
    children: ReactNode
    enterApi: EnterApi
}

/**
 * Provider that wraps your app and provides it with a Enter API
 *
 * @param children The app that is wrapped by this provider
 * @param enterApi Enter API instance @see EnterApi
 *
 * @returns Enter API Provider wrapper component
 */
export const EnterApiProvider = ({ children, enterApi }: EnterApiProviderProps) => (
    <EnterApiContext.Provider value={enterApi}>{children}</EnterApiContext.Provider>
)

/**
 * Hook that is used to easily consume the Enter API Context
 *
 * @returns Enter API instance @see EnterApi
 */
export const useEnterApi = () => useContext(EnterApiContext)

/**
 * Typed wrapper for queries to the Enter API with react-query.
 *
 * @param queryKey Serializable identifier for the query cache
 * @param queryFn Here goes the function that actually communicates to the Enter API
 * @param options Further configuration for the query
 *
 * @returns Typed react-query useQuery hook.
 */
export const useEnterQuery = <TData, TQueryKey extends QueryKey = QueryKey>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TData, TQueryKey>,
    options?: EnterQueryOptions<TData, TQueryKey>
) => useQuery<TData, EnterError, TData, TQueryKey>({ queryKey, queryFn, ...options })

/**
 * Options that configure the useEnterMutation hook.
 */
export type EnterMutationOptions<TData, TPayload> = Omit<
    UseMutationOptions<TData, EnterError, TPayload>,
    'mutationKey' | 'mutationFn'
>

/**
 * Typed wrapper for mutations to the Enter API with react-query.
 *
 * @param mutationKey
 * @param mutationFn
 * @param options Further configuration for useMutation
 *
 * @returns Typed react-query useMutation hook.
 */
export const useEnterMutation = <TData, TPayload, TMutationKey extends MutationKey = MutationKey>(
    mutationKey: TMutationKey,
    mutationFn: MutationFunction<TData, TPayload>,
    options?: EnterMutationOptions<TData, TPayload>
) => useMutation<TData, EnterError, TPayload>({ mutationKey, mutationFn, ...options })
