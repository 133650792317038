import { css } from '@emotion/react'
import { motion, MotionProps } from 'framer-motion'
import { useId } from 'react'
import { useMeasure } from 'react-use'
import { StyleProps, ChildrenProps } from 'lib/types/propTypes'
import { useTheme } from 'lib/styles/theme'

type ErrorMessageProps = ChildrenProps & StyleProps & { offset?: number } & MotionProps

export const ErrorMessage = ({ children, styles, offset = 16, ...motionProps }: ErrorMessageProps) => {
    const theme = useTheme()
    const id = useId()

    const [contentRef, { height }] = useMeasure<HTMLDivElement>()

    return (
        <motion.div
            key={id}
            initial={{ opacity: 0, height: 0 }}
            animate={{
                opacity: 1,
                height: height + offset,
            }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
                opacity: { duration: 0.2 },
                height: {
                    duration: 0.18,
                    type: 'spring',
                    stiffness: 250,
                    damping: 17,
                },
            }}
            {...motionProps}
            css={css`
                position: relative;
                overflow: hidden;
            `}
        >
            <div
                ref={contentRef}
                css={css`
                    position: absolute;
                    bottom: 0;
                    color: ${theme.colors.error};
                    font-size: ${theme.fontSize.error || theme.fontSize.bodyDefault};
                    font-weight: ${theme.fontWeight.error || theme.fontWeight.bodyDefault};
                    line-height: ${theme.lineHeight.error || theme.lineHeight.bodyDefault};
                    font-family: ${theme.fontFamily.bodyDefault};

                    ${styles}
                `}
            >
                {children}
            </div>
        </motion.div>
    )
}
