import Image from 'next/image'
import { css } from '@emotion/react'
import { StyleProps } from 'lib/types/propTypes'
// @ts-ignore
import Logo from '../assets/enter-logo-gradient.png'

export type EnterLogoProps = StyleProps

export const EnterLogo = ({ styles }: EnterLogoProps) => (
    <Image
        priority
        src={Logo}
        alt="Enter"
        height={100}
        width={200}
        quality={100}
        css={css`
            height: auto;
            width: 100px;

            ${styles}
        `}
    />
)
