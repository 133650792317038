import React from 'react'
import { ChildrenProps } from 'lib/types/propTypes'
import { useAuthentication } from 'lib/components/authentication/hooks/useAuthentication'
import { AuthenticationContext } from 'lib/components/authentication/authenticationContext'

export const FirebaseAuthenticationProvider = ({ children }: ChildrenProps) => {
    const { auth, idToken, isLoggedIn, setLoggedState, isLoading } = useAuthentication()

    if (!auth || isLoading) return null

    return (
        <AuthenticationContext.Provider
            value={{
                auth,
                idToken,
                isLoggedIn,
                setLoggedState,
                isLoading,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    )
}
