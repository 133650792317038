import { IconName } from '../../enter-icons/enterIcon'
import { Values } from '../../utils/helpers'

export enum SurveyNamePath {
    hds = 'main',
    isfp = 'appointment',
    'sun-protection' = 'sun-protection',
    'window-replacement' = 'window-replacement',
    'attic-window-replacement' = 'attic-window-replacement',
    'ceiling-insulation' = 'ceiling-insulation',
    'exterior-wall-insulation' = 'exterior-wall-insulation',
    'top-floor-ceiling-insulation' = 'top-floor-ceiling-insulation',
    'basement-ceiling-insulation' = 'basement-ceiling-insulation',
    'door-replacement' = 'door-replacement',
    'heating-replacement' = 'heating-replacement',
    'heating-optimization' = 'heating-optimization',
    'subsidies-service-solar-thermal' = 'subsidies-service-solar-thermal',
}

export enum MeasureNamePath {
    'sun-protection' = 'sun-protection',
    'window-replacement' = 'window-replacement',
    'attic-window-replacement' = 'attic-window-replacement',
    'ceiling-insulation' = 'ceiling-insulation',
    'exterior-wall-insulation' = 'exterior-wall-insulation',
    'top-floor-ceiling-insulation' = 'top-floor-ceiling-insulation',
    'basement-ceiling-insulation' = 'basement-ceiling-insulation',
    'door-replacement' = 'door-replacement',
    'heating-replacement' = 'heating-replacement',
    'heating-optimization' = 'heating-optimization',
    'subsidies-service-solar-thermal' = 'subsidies-service-solar-thermal',
}

export enum SurveyName {
    main = 'hds',
    appointment = 'isfp',
    'sun-protection' = 'sun-protection',
    'window-replacement' = 'window-replacement',
    'attic-window-replacement' = 'attic-window-replacement',
    'ceiling-insulation' = 'ceiling-insulation',
    'exterior-wall-insulation' = 'exterior-wall-insulation',
    'top-floor-ceiling-insulation' = 'top-floor-ceiling-insulation',
    'basement-ceiling-insulation' = 'basement-ceiling-insulation',
    'door-replacement' = 'door-replacement',
    'heating-replacement' = 'heating-replacement',
    'heating-optimization' = 'heating-optimization',
    'subsidies-service-solar-thermal' = 'subsidies-service-solar-thermal',
}

export enum SubsidyServiceName {
    'sun-protection' = 'Förderservice - Sonnenschutz',
    'window-replacement' = 'Förderservice - Fenster tauschen',
    'attic-window-replacement' = 'Förderservice - Dachflächenfenster tauschen',
    'ceiling-insulation' = 'Förderservice - Dach dämmen',
    'exterior-wall-insulation' = 'Förderservice - Fassade dämmen',
    'top-floor-ceiling-insulation' = 'Förderservice - Oberste Geschossdecke dämmen',
    'basement-ceiling-insulation' = 'Förderservice - Kellerdecke dämmen',
    'door-replacement' = 'Förderservice - Haustür tauschen',
    'heating-replacement' = 'Förderservice - Heizung tauschen',
    'heating-optimization' = 'Förderservice - Heizungsoptimierung',
    'subsidies-service-solar-thermal' = 'Förderservice - Solarthermie',
}

export enum BuildingMeasuresValues {
    WINDOW = 'window',
    EXTERIOR_WALL = 'exteriorWall',
    BESAMENT_CEILING = 'basementCeiling',
    TOP_FLOOR_CELLING = 'topFloorCeiling',
    SUN_PROTECTION = 'sunProtection',
    ROOF = 'roof',
    SOLAR_THERMAL_HEATER = 'solarThermalHeater',
    IMPROVED_HEAT_PUMP = 'heatPumpImproved',
    HEAT_PUMP = 'heatPump',
    BIOMASS_HEATER = 'biomassHeater',
    DOOR = 'door',
    BASEMENT_FLOOR_PLATE = 'basementFloorPlate',
    BASEMENT_EXTERIOR_WALL = 'basementExteriorWall',
    FLOOR_PLATE = 'floorPlate',
    EXTERIOR_WALL_THERMAL = 'exteriorWallThermalInsulationCompositeSystem',
}

export const VisibleMeasures = [
    BuildingMeasuresValues.IMPROVED_HEAT_PUMP,
    'heatPump',
    'basementExteriorWall',
    BuildingMeasuresValues.EXTERIOR_WALL,
    'exteriorWallThermalInsulationCompositeSystem',
    BuildingMeasuresValues.WINDOW,
    'roofWindow',
    BuildingMeasuresValues.ROOF,
    BuildingMeasuresValues.DOOR,
    BuildingMeasuresValues.TOP_FLOOR_CELLING,
    BuildingMeasuresValues.BASEMENT_FLOOR_PLATE,
    'floorPlate',
    BuildingMeasuresValues.BESAMENT_CEILING,
]

export type BuildingPartType =
    | 'basementCeiling'
    | 'basementExteriorWall'
    | 'basementFloorPlate'
    | 'roof'
    | 'roofWindow'
    | 'exteriorWall'
    | 'topFloorCeiling'
    | 'wall'
    | 'window'
    | 'door'
    | 'heatPumpImproved'
    | 'heatPump'
    | 'conservatory'

export interface RoboAdviceScore {
    uuid: string
    measure: string
    benchmark: RoboAdviceScoreResult
    renovated: RoboAdviceScoreResult
    savings: {
        savingsInCent: number
        savingsInCentLabel: string
        savingsInEuro: number
        savingsInEuroLabel: string
        savingsInKwH: number
        savingsInKwHLabel: string
        savingsInCO2: number
        savingsInCO2Label: string
        savingsInCentPercentage: number
        savingsInCentPercentageLabel: string
        savingsInKwHPercentage: number
        savingsInKwHPercentageLabel: string
        savingsInCO2Percentage: number
        savingsInCO2PercentageLabel: string
    }
    cost: {
        actualCost: number
        actualCostLabel: string
        amortizationPeriod: string
        amortizationPeriodLabel: string
        renovationCost: string
        renovationCostLabel: string
        renovationYield: string
        renovationYieldLabel: string
        subsidies: number
        subsidiesLabel: string
    }
}

export interface BuildingMeasures {
    componentMetaType: string
    componentType: Values<typeof BuildingMeasuresValues>
    description: string
    subtitle: string
    infoUrl?: string
    savingsInCent: number
    name: string
    shortLabel: string
    thumbnailUrl: string
    iconName: IconName | string
    measureUrl?: string
    amortizationPeriod: string
    renovationCosts: string
    subsidies: string
}

export interface RoboAdviceScoreResult {
    spend: number
    emissions: number
    energyDemand: number
    energyScore: EnergyScore
    energyClass: EnergyClass
}

export type EnergyScore = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export type EnergyClass = 'A+' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H'

export interface RoboAdviceScoreVersion {
    uuid: string
    version: string
    roboAdviceScores?: { data: RoboAdviceScore[] }
    recommendedMeasures: BuildingMeasures[]
}

export interface GetBuildingSurveyStatus {
    hdsStatus: FormProgressStatus
    isfpStatus: FormProgressStatus
    sunProtectionStatus: FormProgressStatus
    windowReplacementStatus: FormProgressStatus
    atticWindowReplacementStatus: FormProgressStatus
    ceilingInsulationStatus: FormProgressStatus
    exteriorWallInsulationStatus: FormProgressStatus
    topFloorCeilingInsulationStatus: FormProgressStatus
    basementCeilingInsulationStatus: FormProgressStatus
    doorReplacementStatus: FormProgressStatus
    heatingReplacementStatus: FormProgressStatus
    heatingOptimizationStatus: FormProgressStatus
    solarThermalStatus: FormProgressStatus
}

export interface GetBuildings extends GetBuildingSurveyStatus {
    uuid: string
    ownerUUID?: string
    roboAdviceInfoStatus: RoboAdviceInfoStatus
    applicantOwnerStatus: FormProgressStatus
    subsidiesBuildingStatus: FormProgressStatus
    landSpaceStatus: FormProgressStatus
    currentRoboAdviceVersion: { data?: RoboAdviceScoreVersion } | null
    serviceLineItems?: { data?: ServiceLineItem[] }
    renovationInterests?: { data?: RenovationInterest[] }
    roofShape?: string
    address?: {
        data?: Address
    }
}

export interface ProjectProfitability extends BuildingMeasures {
    profit: {
        subsidiesLabel: string
        savingsInCentPercentageLabel: string
    }
}

export enum SavingsPotentialUnit {
    EURO = 'euro',
    KWH = 'kWh',
    CO2 = 'co2',
}

export interface Address {
    addressString?: string

    city?: string
    country?: string
    state?: string
    street?: string
    zipCode?: string
}

export enum RoboAdviceInfoStatus {
    noScoreInfoShown = 'noScoreInfoShown',
    emptyScoreInfoShown = 'emptyScoreInfoShown',
    firstRoboScoreInfoShown = 'firstRoboScoreInfoShown',
}

export type ServiceLineItem = {
    uuid: string
    currentStage: OpportunityStage
    type: OpportunityType
    areInitialRequiredUserDocumentsUploaded?: boolean
    isBafaPowerOfAttorneyDocumentUploaded?: boolean
    subsidiesService?: { data?: SubsidiesService }
    bafaVollmachtDocuments: string[]
}

export type RenovationInterest = {
    uuid?: string
    accountId?: string
    origin?: string
    renovationInterest: string
    updatedAt?: Date
    image?: string
    label?: string
}

export type SubsidiesService = {
    subType: `${SubsidyServiceName}`
    measureFormProgressStatus: FormProgressStatus
    uuid: string
}

export enum FormProgressStatus {
    formFillingNotStarted = 'formFillingNotStarted',
    formFillingStarted = 'formFillingStarted',
    formFillingStartedPopupShown = 'formFillingStartedPopupShown',
    formFillingEnded = 'formFillingEnded',
}

export enum OpportunityStage {
    InitialConsultation = 'InitialConsultation', // we dont' show modal
    Offer = 'Offer', // we dont' show modal

    Basics = 'Basics',
    Qualification = 'Qualification',
    Application = 'Application', // doesn't exist anymore
    OnPremiseAppointment = 'OnPremiseAppointment',

    QualityOperations = 'QualityOperations',
    Project = 'Project',

    Documentation = 'Documentation',

    Closed = 'Closed',
    ClosedWon = 'ClosedWon',
    ClosedLost = 'ClosedLost',
}

export enum SubsidyOpportunityStage {
    OnPremiseAppointment = 'OnPremiseAppointment',
    QualityOperations = 'QualityOperations',
    Marketplace = 'Marketplace',

    Basics = 'Basics',
    Qualification = 'Qualification',

    Application = 'Application',

    Project = 'Project',

    Documentation = 'Documentation',

    Closed = 'Closed',
    ClosedWon = 'ClosedWon',
    ClosedLost = 'ClosedLost',
}

export enum OpportunityType {
    SubsidiesService = 'subsidiesService',
    RenovationSchedule = 'renovationSchedule',
}

export type BuildingsInclude =
    | 'currentRoboAdviceVersion.roboAdviceScores'
    | 'serviceLineItems'
    | 'serviceLineItems.subsidiesService'
    | 'serviceLineItems.areInitialRequiredUserDocumentsUploaded'
    | 'serviceLineItems.isBafaPowerOfAttorneyDocumentUploaded'
    | 'roofShape'
    | 'address'

export enum MeasureSourceType {
    AtticWindowReplacement = 'attic-window-replacement',
    BAFAPromotion = 'bafa-promotion',
    BasementCeilingInsulation = 'basement-ceiling-insulation',
    BuildingApplication = 'building-application',
    CeilingInsulation = 'ceiling-insulation',
    DoorReplacement = 'door-replacement',
    ExteriorWallInsulation = 'exterior-wall-insulation',
    ExtensionApplication = 'extension-application',
    ExtensionInventoryAssessment = 'extension-inventory-assessment',
    Facade = 'facade',
    FacadeInsulation = 'facade-insulation',
    FacadeRoofBasementCeilingInsulation = 'facade-roof-basement-ceiling-insulation',
    FacadeRoofBasementHeatingReplacement = 'facade-roof-basement-heating-replacement',
    FacadeRoofBasementWindowHeatingReplacement = 'facade-roof-basement-window-heating-replacement',
    FacadeRoofBasementWindowHeatingSunProtection = 'facade-roof-basement-window-heating-sun-protection',
    FacadeRoofHeatingReplacement = 'facade-roof-heating-replacement',
    FacadeRoofInsulation = 'facade-roof-insulation',
    FacadeRoofWindowHeatingReplacement = 'facade-roof-window-heating-replacement',
    FacadeRoofWindowHeatingSunProtection = 'facade-roof-window-heating-sun-protection',
    FacadeRoofWindowReplacement = 'facade-roof-window-replacement',
    FacadeSunProtection = 'facade-sun-protection',
    FrontDoorReplacement = 'front-door-replacement',
    HeatingOptimization = 'heating-optimization',
    HeatingReplacement = 'heating-replacement',
    HeatingSystemReplacement = 'heating-system-replacement',
    InventoryAssessment = 'inventory-assessment',
    OtherMeasures = 'other-measures',
    Roof = 'roof',
    RoofBasementWindowReplacement = 'roof-basement-window-replacement',
    RoofFacadeHeatingReplacement = 'roof-facade-heating-replacement',
    RoofFacadeInsulation = 'roof-facade-insulation',
    RoofHeatingReplacement = 'roof-heating-replacement',
    RoofInsulation = 'roof-insulation',
    RoofWindowHeatingReplacement = 'roof-window-heating-replacement',
    RoofWindowHeatingSunProtection = 'roof-window-heating-sun-protection',
    RoofWindowReplacement = 'roof-window-replacement',
    RoofWindowSunProtection = 'roof-window-sun-protection',
    SolarThermal = 'solar-thermal',
    SunProtection = 'sun-protection',
    TopFloorCeilingInsulation = 'top-floor-ceiling-insulation',
    WindowOrDoorReplacement = 'window-or-door-replacement',
    WindowReplacement = 'window-replacement',
    Wintergarten = 'conservatory',
}

export enum FolderSourceType {
    RenovationSchedule = 'renovation-schedule',
    SubsidiesService = 'subsidies-service',
}
