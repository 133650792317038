import { LoadingSpinner as BaseLoadingSpinner } from 'lib/components/loading/loadingSpinner'
import { EnterLogo } from 'lib/components/enterLogo'
import { useTheme } from 'lib/styles/theme'
import { css } from '@emotion/react'

export const LoadingIndicator = () => {
    const theme = useTheme()

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: ${theme.spacing(2)};
                height: 100%;
                margin: auto;
            `}
        >
            <BaseLoadingSpinner width="50px" height="50px" color={theme.colors.text.primary.onLight} />

            <EnterLogo />
        </div>
    )
}
