import { css } from '@emotion/react'
import { createGlobalStyle } from 'lib/styles/global'
import { THEME } from 'lib/styles/theme'

export const globalStyles = createGlobalStyle(
    THEME,
    css`
        //html,
        //body,
        #__next,
        body {
            height: 100%;
            min-height: 100vh;
        }

        // This is a fix for a iOS issue, in which iOS changes the color of link and button text to blue.
        a,
        button {
            color: inherit;
        }
    `
)
