import { BAUPAL_COLORS } from './palette'

export default {
    error: {
        background: BAUPAL_COLORS.generic.red.light,
        color: BAUPAL_COLORS.generic.red.dark,
        support: BAUPAL_COLORS.generic.red.standard,
    },
    warning: {
        background: BAUPAL_COLORS.generic.yellow.light,
        color: BAUPAL_COLORS.generic.yellow.dark,
        support: BAUPAL_COLORS.generic.yellow.standard,
    },
    success: {
        background: BAUPAL_COLORS.generic.green.light,
        color: BAUPAL_COLORS.generic.green.standard,
        support: BAUPAL_COLORS.generic.green.standard,
    },
    info: {
        background: BAUPAL_COLORS.generic.neutral.standard,
        color: BAUPAL_COLORS.generic.neutral[60],
        support: BAUPAL_COLORS.generic.neutral[60],
    },
}
