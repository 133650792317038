import { css, SerializedStyles } from '@emotion/react'
import { useTheme } from 'lib/styles/theme'
import { RequireSome } from 'lib/types/advanced'
import { forwardRef, ReactNode, useCallback } from 'react'
import ReactSelect, { GroupBase, Props as ReactSelectProps, SelectInstance } from 'react-select'
import ReactAsyncSelect, { AsyncProps as ReactAsyncSelectProps } from 'react-select/async'
import { InputContainer } from 'lib/components/forms/inputContainer'
import { run } from 'lib/utils/helpers'
import { INPUT_PADDING_PX, INPUT_MIN_HEIGHT, INPUT_BORDER } from 'lib/components/forms/textInput'

export interface SelectOption<T> {
    label: string | ReactNode
    value: T
}

type ReactSelectBaseProps<T, TMulti extends boolean> = Omit<
    RequireSome<ReactSelectProps<SelectOption<T>, TMulti>, 'value' | 'onChange' | 'isMulti'>,
    'styles' | 'options'
>

type ReactSelectOwnProps<T, TMulti extends boolean> = {
    onBlur?: () => void
    onFocus?: () => void
    label?: string
    error?: string | null
    clearError?: () => void
    labelStyles?: SerializedStyles
    containerStyles?: SerializedStyles
    stylesConfig?: ReactSelectProps<SelectOption<T>, TMulti>['styles']
    styleType?: 'default' | 'inverted'
    options:
        | ReactSelectProps<SelectOption<T>, TMulti>['options']
        | ReactAsyncSelectProps<SelectOption<T>, TMulti, GroupBase<SelectOption<T>>>['loadOptions']
}

export type SelectProps<T, TIsMulti extends boolean> = ReactSelectOwnProps<T, TIsMulti> &
    ReactSelectBaseProps<T, TIsMulti>

function SelectWithoutRef<T, TMulti extends boolean>(
    {
        label,
        error,
        clearError,
        labelStyles,
        containerStyles,
        onBlur,
        onFocus,
        styleType,
        stylesConfig: {
            control: controlConfig,
            valueContainer: valueContainerConfig,
            input: inputConfig,
            dropdownIndicator: dropdownIndicatorConfig,
            indicatorSeparator: indicatorSeparatorConfig,
            menu: menuConfig,
            option: optionConfig,
            ...stylesConfig
        } = {},
        options,
        ...props
    }: SelectProps<T, TMulti>,
    ref: React.ForwardedRef<null>
) {
    const theme = useTheme()

    const handleBlur = useCallback(() => {
        onBlur?.()
    }, [onBlur])

    const handleFocus = useCallback(() => {
        clearError?.()
        onFocus?.()
    }, [onFocus, clearError])

    const baseProps: ReactSelectProps<SelectOption<T>, TMulti> = {
        blurInputOnSelect: true,
        onBlur: handleBlur,
        onFocus: handleFocus,
        styles: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            control: (provided, state: any) => {
                const defaultStyles = {
                    ...provided,
                    cursor: 'pointer',
                    transition: 'all 0.3s ease-out',
                    fontSize: theme.fontSize.input || theme.fontSize.bodyDefault,
                    fontWeight: theme.fontWeight.input || theme.fontWeight.bodyDefault,
                    padding: INPUT_PADDING_PX,
                    height: INPUT_MIN_HEIGHT,
                    color: theme.colors.onInput,
                    border: INPUT_BORDER,
                    borderRadius: '18px',
                    background: styleType === 'inverted' ? theme.colors.background.light : theme.colors.input,
                    minWidth: '100px',
                    boxShadow: 'none',
                    overflow: 'hidden',
                }

                return { ...defaultStyles, ...controlConfig?.(defaultStyles, state) }
            },
            valueContainer: (provided, state) => {
                const defaultStyles = {
                    ...provided,
                    height: 'auto',
                    padding: 0,
                }

                return { ...defaultStyles, ...valueContainerConfig?.(defaultStyles, state) }
            },
            input: (provided, state) => {
                const defaultStyles = {
                    ...provided,
                    margin: 0,
                    padding: 0,
                }

                return { ...defaultStyles, ...inputConfig?.(defaultStyles, state) }
            },
            dropdownIndicator: (provided, state) => {
                const defaultStyles = {
                    ...provided,
                    padding: 0,
                }

                return { ...defaultStyles, ...dropdownIndicatorConfig?.(defaultStyles, state) }
            },
            indicatorSeparator: (provided, state) => {
                const defaultStyles = {
                    ...provided,
                    display: 'none',
                }

                return { ...defaultStyles, ...indicatorSeparatorConfig?.(defaultStyles, state) }
            },
            menu: (provided, state) => {
                const defaultStyles = {
                    ...provided,
                    borderRadius: '18px',
                    backgroundColor: theme.colors.background.standard,
                    overflow: 'hidden',
                }

                return { ...defaultStyles, ...menuConfig?.(defaultStyles, state) }
            },
            option: (provided, state) => {
                const defaultStyles = {
                    ...provided,
                    backgroundColor: state.isFocused ? theme.colors.secondary.light : 'unset',
                    color: theme.colors.text.primary.onLight,
                }

                return { ...defaultStyles, ...optionConfig?.(defaultStyles, state) }
            },
            ...stylesConfig,
        },
    }

    return (
        <InputContainer label={label} error={error} containerStyles={containerStyles} labelStyles={labelStyles}>
            {run(() => {
                if (typeof document === 'undefined') return

                const root = document.getElementById('select-root')

                if (typeof options === 'function') {
                    return (
                        <ReactAsyncSelect
                            ref={ref}
                            loadOptions={options}
                            menuPortalTarget={root}
                            {...baseProps}
                            {...props}
                        />
                    )
                }

                return <ReactSelect ref={ref} options={options} menuPortalTarget={root} {...baseProps} {...props} />
            })}
        </InputContainer>
    )
}

export const Select = forwardRef(SelectWithoutRef) as <T, TIsMulti extends boolean>(
    props: SelectProps<T, TIsMulti> & { ref?: React.ForwardedRef<SelectInstance> }
) => ReturnType<typeof SelectWithoutRef>

export const SelectRoot = () => {
    const theme = useTheme()
    return (
        <div
            id="select-root"
            css={css`
                z-index: ${theme.zIndex.overlay};
            `}
        />
    )
}
