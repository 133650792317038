import { ThemeTypeScale } from '../theme'

export const typeScale: ThemeTypeScale = {
    display: {
        small: {
            size: 28,
            height: 42,
            weight: 600,
            spacing: 0,
        },
        medium: {
            size: 40,
            height: 60,
            weight: 600,
            spacing: 0,
        },
        large: {
            size: 45,
            height: 62,
            weight: 600,
            spacing: 0,
        },
    },
    headline: {
        small: {
            size: 18,
            height: 27,
            weight: 600,
            spacing: 0,
        },
        medium: {
            size: 20,
            height: 30,
            weight: 600,
            spacing: 0,
        },
        large: {
            size: 24,
            height: 36,
            weight: 600,
            spacing: 0,
        },
    },
    title: {
        small: {
            size: 14,
            height: 20,
            weight: 600,
            spacing: 0.1,
        },
        medium: {
            size: 16,
            height: 24,
            weight: 600,
            spacing: 0.15,
        },
        large: {
            height: 20,
            size: 30,
            weight: 600,
            spacing: 0,
        },
    },
    body: {
        small: {
            size: 12,
            height: 18,
            weight: 500,
            spacing: 0.25,
        },
        medium: {
            size: 14,
            height: 22,
            weight: 500,
            spacing: 0.25,
        },
        large: {
            size: 16,
            height: 24,
            weight: 500,
            spacing: 0.25,
        },
    },
    label: {
        small: {
            size: 11,
            height: 14,
            weight: 500,
            spacing: 0.2,
        },
        medium: {
            size: 12,
            height: 18,
            weight: 500,
            spacing: 0.2,
        },
        large: {
            size: 14,
            height: 22,
            weight: 500,
            spacing: 0.1,
        },
    },
}
